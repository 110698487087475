/*******************************
         Menu Overrides
*******************************/
#navbar.ui.menu .active.item.navbaritem {
  color: @navbarActiveItemColor;
}

#navbar.ui.menu .item.navbaritem {
  color: @navbarItemColor;
}

#navbar.ui.menu .item.navbaritem svg {
  stroke: @navbarItemColor;
}

#navbar.ui.menu .active.item.navbaritem svg {
  stroke: @navbarActiveItemColor;
}

.ui.vertical-menu.menu .active.item {
  border-left: solid;
  border-color: @primaryColor;
}

.ui.vertical-menu.menu .item {
  padding: 1.2rem;
}